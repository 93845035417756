import moment from "moment";
import intl from "react-intl-universal";
import format from "date-fns/format";
import { getDateFnsLocale } from "./localization";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";
export const HUMAN_DATETIME_FORMAT = "dddd D MMMM";

export const isSameDateTime = (date1, date2) =>
  moment(date1).format(DATETIME_FORMAT) === moment(date2).format(DATETIME_FORMAT);

export const formatTime = (date) => {
  const isTimeStringHHMM = typeof date === "string" && date.length === 5 && date.split(":").length === 2;
  if (isTimeStringHHMM) {
    const secondsPart = ":00";
    date += secondsPart;
  }
  const isTimeStringHHMMSS = typeof date === "string" && date.length === 8 && date.split(":").length === 3;
  if (isTimeStringHHMMSS) {
    // add a fake date to be able to use momentjs date formatting
    date = `1980-10-06 ${date}`;
  }

  let dateMoment = moment(date);
  if (!dateMoment.isValid()) {
    dateMoment = moment(`${moment().startOf("day").format(DATE_FORMAT)} ${date}`);
  }
  return format(dateMoment.toDate(), "p", { locale: getDateFnsLocale() });
};

export const formatDate = (dateTime) => moment(dateTime).format(DATE_FORMAT);

export const formatDuration = (durationString) => {
  let minutes = durationString;
  if (typeof minutes === "string" && minutes.indexOf(":") > -1) {
    // duration is of format 02:30:00
    // date does not matter since we just need a date to be able to use
    // momentjs
    minutes = moment(`1980-10-06 ${durationString}`).diff("1980-10-06", "minutes");
  }

  if (minutes === 0) {
    return "0 minuter";
  }

  let label = [];
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0) {
    label.push(hours === 1 ? "1 timme" : `${hours} timmar`);
  }
  if (remainingMinutes > 0) {
    label.push(remainingMinutes + " minuter");
  }
  return label.join(", ");
};

export const formatDateTime = (date) => moment(date).format(DATETIME_FORMAT);

/**
 *
 * @param {string|moment} date
 */
export const formatHumanDate = (date) => {
  const dateToFormat = moment(date);
  if (moment().isSame(dateToFormat, "day")) {
    return intl.get("menu.today");
  } else if (moment().add(1, "days").isSame(dateToFormat, "day")) {
    return intl.get("menu.tomorrow");
  }
  return dateToFormat.format(HUMAN_DATETIME_FORMAT);
};

/**
 *
 * @param {string} date iso formatted date, ie 2020-10-06
 * @param {string} timeString time, 11:00 for example. format hh:mm
 */
export const getMomentFromDateAndTimeString = (date, timeString) => {
  const [hour, minute] = timeString.split(":");
  return moment(date).startOf("day").add(parseInt(hour), "hour").add(parseInt(minute), "minutes");
};

/**
 * this function is a clone of the function that exists in the server code
 * @param {moment} now
 * @param {array} takeAwayAvgWaitingIntervals each element contains a object with properties like: {start: '11:00', end:'13:00',waitMinutes: 15}
 * @param {integer} takeAwayAvgWaitingMinutes default waiting time
 */
export const getWaitingTime = (now, takeAwayAvgWaitingIntervals, takeAwayAvgWaitingMinutes) => {
  try {
    if (Array.isArray(takeAwayAvgWaitingIntervals)) {
      for (let timeInterval of takeAwayAvgWaitingIntervals) {
        const start = getMomentFromDateAndTimeString(now.format("YYYY-MM-DD"), timeInterval.start);
        const end = getMomentFromDateAndTimeString(now.format("YYYY-MM-DD"), timeInterval.end);
        if (now.isSameOrAfter(start) && now.isBefore(end)) {
          return timeInterval.waitMinutes;
        }
      }
    }
  } catch (error) {
    console.error("problem with parsing takeAwayAvgWaitingIntervals: ", error);
  }
  return takeAwayAvgWaitingMinutes;
};

export const getIsoDayLabel = (isoDay) => {
  switch (isoDay) {
    case 1:
      return "Måndag";
    case 2:
      return "Tisdag";
    case 3:
      return "Onsdag";
    case 4:
      return "Torsdag";
    case 5:
      return "Fredag";
    case 6:
      return "Lördag";
    case 7:
      return "Söndag";

    default:
      return null;
  }
};
